<template>
  <div class="wrap pa-8 px-sm-3 py-sm-6">
    <header class="d-flex flex-column">
      <v-img
        min-width="163"
        max-width="165"
        :src="getAdNetworkImages.theme_black_logo"
        class="mx-auto mb-4 mb-sm-8"
      />
      <div class="d-flex flex-column flex-sm-row align-center justify-center mt-10 mt-xs-8 mb-4">
        <success-icon class="primary--text" />
        <span :class="['white--text text-h4 text-center', { 'text-truncate': !isMobile }]">
          {{ $t('register.registration_succeeded') }}
        </span>
      </div>
    </header>

    <div class="d-flex flex-column align-center mt-10">
      <span class="white--text text-subtitle-1"> {{ $t('register.how_to_create_campaign') }} </span>
      <div :class="['mt-6 mt-xs-4 video-wrap', `video-wrap--${$vuetify.breakpoint.name}`]">
        <iframe
          :width="iframeSizes.width"
          :height="iframeSizes.height"
          class="frame"
          :src="helpVideosUrls.simple_tutorial_to_start_affiliate"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>
      <c-btn
        :label="$t('main.create_campaign')"
        color="primary"
        class="button-create-campaign font-weight-medium white--text text-body-2 mt-9 px-12 px-sm-8 py-4 py-sm-3"
        @click="onCreateCampingClick()"
      />
    </div>
  </div>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { mapGetters } from 'vuex'
  import SuccessIcon from '@/views/RegistrationSucceededPage/SuccessIcon.vue'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'RegistrationSucceededPage',
    components: {
      CBtn,
      SuccessIcon
    },
    computed: {
      ...mapGetters('settings', ['getAdNetworkImages', 'helpVideosUrls']),
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      iframeSizes() {
        return (
          {
            sm: {
              width: 420,
              height: 300
            },
            md: {
              width: 620,
              height: 400
            },
            xs: {
              width: 300,
              height: 180
            }
          }[this.$vuetify.breakpoint.name] ?? { width: 989, height: 556 }
        )
      }
    },
    methods: {
      async onCreateCampingClick() {
        await this.$router.push({ name: routeNames.MAIN })
      }
    }
  }
</script>

<style scoped lang="scss">
  .wrap {
    min-height: 100vh;
    background-color: #0c0a17;
  }

  .button-create-campaign {
    position: relative;
    box-shadow: 0 0 14px var(--v-primary-base);
    transition: 0.3s ease-in;
  }

  .video-wrap {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      width: 586px;
      height: 366px;
      background: var(--v-primary-base);
      filter: blur(120px);
    }

    .frame {
      isolation: isolate;
    }

    &--sm {
      &:before {
        width: 250px;
        height: 200px;
      }
    }

    &--md {
      &:before {
        width: 350px;
        height: 300px;
      }
    }

    &--xs {
      &:before {
        width: 150px;
        height: 100px;
      }
    }
  }
</style>
