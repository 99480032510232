<template>
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="filter: drop-shadow(0 0 4px currentColor)"
  >
    <path
      d="M27.3325 18.4436L21.7579 24.1098C20.8102 25.073 20.3363 25.5547 19.7475 25.5547C19.1587 25.5547 18.6848 25.0731 17.7371 24.1098L16.6658 23.0208M34.4436 21.9991C34.4436 28.872 28.872 34.4436 21.9991 34.4436C15.1263 34.4436 9.55469 28.872 9.55469 21.9991C9.55469 15.1263 15.1263 9.55469 21.9991 9.55469C28.872 9.55469 34.4436 15.1263 34.4436 21.9991Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
  export default {
    name: 'SuccessIcon'
  }
</script>
